@import "theme.less";

.applicationPage {
  @apply flex flex-row flex-grow items-stretch h-screen overflow-y-hidden;
}

.applicationPageComponent {
  @apply pt-4 pb-4;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
}

.sidebar {
  @apply flex items-stretch h-full;
}

.rightbar {
  @apply flex flex-col flex-grow justify-start h-full px-4 pt-3;
  width: calc(100vw - 16rem);
  min-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.coloredMenuItem {
  i {
    transform: scale3d(1.2, 1.2, 1);
  }
  svg {
    fill: inherit !important;
  }
}

.menuItem {
  i {
    transition: transform 0.1s ease-in-out;
  }
}

.menuItem:hover {
  .coloredMenuItem();
}

.selectedMenuItem {
  .coloredMenuItem();
  border-left: 1px solid @splitBorder;
  border-top: 1px solid @splitBorder;
  border-bottom: 1px solid @splitBorder;
}

.sideBarContent {
  @apply flex flex-col justify-between h-full;
  width: 100%;
  min-width: 100%;
  background-color: @bgPrimary;
}

.sideBarContent_item__withRightBorder {
  border-right: 1px solid @splitBorder;
}

.sideBarContent :global .ant-menu {
}

.sideBarContent :global .ant-menu-item-selected {
  border-right: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  color: @link-color;

  a {
    font-weight: bold !important;
    color: @link-color;
  }
}

.sideBarContent :global .ant-menu-item {
  width: auto;
  margin-left: 12px;
  padding-left: 12px !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.sideBarContent :global .ant-menu-item::after {
  border-right: 0 !important;
}

.sideBarContent :global .ant-menu-item-active {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.sideBarContent :global .ant-menu-item-selected.ant-menu-item-active {
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.sideBarContent_applicationMenu {
  margin-right: -1px;
  background-color: @bgPrimary;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.emailHighlight {
  color: @warning;
}

.expandButton:not(:hover):not(:active):not(:active:focus) {
  color: @secondaryText !important;
}
